.nav-link {
    color: #1e3547 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    transition: all 0.5s;
    margin: 0 10px;
}

.nav-link:hover {
    color: #1ea59a !important;
}

.nav-separator {
    width: 100%;
    height: 7px;
    position: relative;
    background-color: #2dca38;
    background: linear-gradient(to right,#1ea59a 0%, #1ea59a 75%, #1ea59a 100%);
    text-shadow: 0 0 1px #000, 0 1px 1px rgb(0 0 0 / 30%);
    font-family: Verdana,sans-serif;
    line-height: 26px;
    color: #fff;
    display: block;
}

.btn-success {
    background: #1ea59a !important;
    border-color: #1ea59a !important;
}

.btn-success:hover {
    background: #21b0a4 !important;
    border-color: #21b0a4 !important;
}

.btn-soft-success {
    background-color: rgba(30, 165, 154, 0.25) !important;
    color: #1ea59a !important;
    transition: all 0.4s;
    font-weight: 600 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.table-item-0 button, .table-item-0 .accordion-button:not(.collapsed) {
    font-weight: bold;
    color: #000000;
    background-color: #fefefe;
    border-color: #fdfdfe;
    border-radius: 0 !important;
}

.table-item-1 button, .table-item-1 .accordion-button:not(.collapsed) {
    font-weight: bold;
    color: #000000;
    background-color: #ffd66e;
    border-color: #d6d8db;
    border-radius: 0 !important;
}

.table-item-2 button, .table-item-2 .accordion-button:not(.collapsed) {
    font-weight: bold;
    color: #000000;
    background-color: #ff3547;
    border-color: #f5c6cb;
    border-radius: 0 !important;
}

.table-item-3 button, .table-item-3 .accordion-button:not(.collapsed) {
    font-weight: bold;
    color: #000000;
    background-color: #59f27d;
    border-color: #c3e6cb;
    border-radius: 0 !important;
}

.status-label-0 {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ced1d5;
    border-radius: 4px;
    font-size: 18px;
    background: #fefefe;
    border: 1px solid #d6d8db;
    font-weight: bold;
}

.status-label-1 {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ced1d5;
    border-radius: 4px;
    font-size: 18px;
    background: #ffd66e;
    border: 1px solid #d6d8db;
    font-weight: bold;
}

.status-label-2 {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ced1d5;
    border-radius: 4px;
    font-size: 18px;
    background: #ff3547;
    border: 1px solid #d6d8db;
    font-weight: bold;
}

.status-label-3 {
    width: 100%;
    padding: 10px 0;
    border: 1px solid #ced1d5;
    border-radius: 4px;
    font-size: 18px;
    background: #59f27d;
    border: 1px solid #d6d8db;
    font-weight: bold;
}

.status-filtered-0 {
    border: 2px solid black;
    font-weight: bold;
}

.status-filtered-1 {
    border: 2px solid black;
    font-weight: bold;
}

.status-filtered-2 {
    border: 2px solid black;
    font-weight: bold;
}

.status-filtered-3 {
    border: 2px solid black;
    font-weight: bold;
}

.status-square-0 {
    display: inline-block;
    height: 13px;
    width: 13px;
    background: #fefefe;
    border: 1px solid #a3a9af;
    border-radius: 50%;
    margin-right: 4px;
}

.status-square-1 {
    display: inline-block;
    height: 13px;
    width: 13px;
    background: #ffd66e;
    border: 1px solid #a3a9af;
    border-radius: 50%;
    margin-right: 4px;
}

.status-square-2 {
    display: inline-block;
    height: 13px;
    width: 13px;
    background: #ff3547;
    border: 1px solid #a3a9af;
    border-radius: 50%;
    margin-right: 4px;
}

.status-square-3 {
    display: inline-block;
    height: 13px;
    width: 13px;
    background: #59f27d;
    border: 1px solid #a3a9af;
    border-radius: 50%;
    margin-right: 4px;
}