@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size:16px;
  color:#1e3547;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#navigation {
  margin-top: 5%;
  margin-bottom: 5%;
}

#navigation a {
  margin-right: 10%;
}

#navigation a:last-child {
  margin-right: 0;
}

thead th {
  padding-right: 50px;
}

#print_component{
  text-align: center;
}